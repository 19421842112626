.important-link {
   display: flex !important;
   flex-direction: row;
   width: 50%;
   justify-content: center;
   align-items: center;
}

.important-link-data {
   border-right: 1.5px solid black;
   padding: 5px;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 20px;
}

.layout-footer {
   background-color: #54d2e0;
   padding: 2px;
   display: flex;
   justify-content: space-between;
   height: auto;
   font-size: 14px;
   font-weight: 500;
}

.size-box {
   width: 95%;
   display: flex !important;
}

.footer {
   display: flex;
   width: 50%;
   justify-content: center;
   align-items: center;
}