body {
  margin: 0;
  background: linear-gradient(to right, #54d2e0, #0857b1);
  font-family: 'matter-regular', 'matter-light', 'matter-bold', 'matter-medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  background-color: linear-gradient(to right, #54d2e0, #0857b1) !important;
  color: white;
}