.visa-parent-container {
   display: flex;
   background: linear-gradient(to right, #54d2e0, #0857b1) !important;
}

.leftContainer {
   padding: 30px;
   width: 30%;
   height: auto;
}

.rightContainer {
   padding: 30px;
   flex: 1;
   width: calc(100% - 40%);
   border-radius: 10px;
}


.disclaimer {
   margin-top: 5px;
   background: linear-gradient(to right, #54d2e0, #0857b1) !important;
   display: flex;
   align-items: center;
   justify-content: center;
   font-weight: 500;
}

.disclaimer p {
   padding: 3% !important;
   color: black !important;
}

.secure-payment {
   margin-top: 50px;
}

.disclaimer p {
   border-radius: 10px;
   width: 95%;
   padding: 15px;
   background: linear-gradient(to right, #cdffd8, #94b9ff) !important;
   color: white;
}

.how-to-apply {
   background: white !important;
   display: flex;
   align-items: center;
   justify-content: center;
}

.how-to-apply-info {
   background: white !important;
}

.how-to-apply-info h5 {
   display: flex;
   align-items: center;
   justify-content: center;
}


.visa-title {
   background: linear-gradient(to right, #54d2e0, #0857b1) !important;
   color: black;
   font-weight: 600;
   font-size: larger;
   text-shadow: 0.5px 0.5px 1px gray;
   display: flex;
   align-items: center;
   justify-content: center;
   margin: 2px;
   width: auto;
   /* position: relative; */
}

.visa-container {
   display: flex;
   justify-items: start !important;
   align-items: start !important;
   flex-direction: column;
   background-image: url('../images/srilanka-map.png');
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center;
   opacity: 0.5;
   z-index: -1;
}

.info-list {
   color: black !important;
   opacity: 1 !important;
   z-index: 10;
   font-weight: 700;
   display: flex;
   flex-direction: column;
   gap: 10px;
}

.info-list li {
   text-shadow: 0.4px 0.4px 0.8px red;
   height: auto;
   width: auto;
}

.passport {
   opacity: 0.5;
}

.visa-approved {
   opacity: 0.5;
   transform: translate(15%, -25%);
}

.page-container {
   background-color: white !important;
   width: 95% !important;
   height: 100% !important;
   border-radius: 10px ;
}

.visa-content {
   width: 100%;
   height: 500PX;
}
.image-container{ 
   width: 100%;
   height: 400px;
} 
.fixed-height-image{ 
   object-fit: contain;
} 

.side_item_one {
   background-color: #fff;
   border-radius: 10px;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   padding: 10px;
   border: 1px solid #e0e0e0;
   width: 100%;
   max-width: 300px;
   font-family: Arial, sans-serif;
   color: #333;
   text-align: left;
   transition: box-shadow 0.3s ease;
   margin-bottom: 5px;
   margin-top: 10px;
 }

 .impss{ 
   padding: 6px 6px;
   margin: 4px;
   border-radius: 5px;
   background: #16832a;
   color: white;
   align-items: center;
   font-size: 24px;
 }


 .scroll-container {
   width: 100%;
   height: 200px; /* Set your desired height */
   overflow: hidden;
   position: relative;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
 }
 
 .scroll-paragraph {
   font-size: 16px;
   margin: 10px 0;
   line-height: 1.5;
 }
 
 .scroll-container {
   overflow: hidden;
 }
 
 .scroll-container p {
   position: relative;
   animation: scroll-up 10s linear infinite; /* Adjust time */
 }
 .side_item_two{ 
   background-color: #fff;
   border-radius: 10px;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   padding: 10px;
   border: 1px solid #e0e0e0;
   width: 100%;
   max-width: 300px;
   font-family: Arial, sans-serif;
   color: #333;
   text-align: left;
   transition: box-shadow 0.3s ease;
 }
 
.side_item_two ul{ 
   list-style: none;
 }
 .side_item_two ul li a{
   text-decoration: none; 
 color: black;
 } 

 .side_item_three img{ 
   width: 300px;
   height: 175px;
   object-fit: contain;
 }

 .side_item_four img{ 
   width: 300px;
   height: 175px;
   object-fit: contain;
 } 