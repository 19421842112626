.pagesa4 {
    background-color: #fff;
    padding: 10px;
    text-align: start;
  }
  input#checkbox { 
    border: 2px solid black;
  }
  .Partial_form{ 
    display: flex;
    justify-content: center;
  }