.eligible-country-list {
   background: linear-gradient(to right, #54d2e0, #0857b1) !important;
   padding: 2%;
}

.table-body {
   border: 13px solid white;
   width: 100%;

}

.country-list-container {
   display: flex;
   flex-direction: column;
   font-weight: 500;
   padding: 2dvi;
}

.country-list-name {
   border: 1px solid gray;
   border-radius: 10px;
   padding: 2%;
   background-color: white;
}

.contact_us{ 
 text-align: center;
}
.ccinput{ 
   border: 1px solid red;
}
