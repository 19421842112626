* {
  margin: 0;
  padding: 0;
}

.borderxdf video {
  height: 450px;
  width: 100%;
  object-fit: inherit;
}

.borderxdf {
  position: relative
}

/*@import url(//fonts.googleapis.com/css?family=Roboto:400,300,500);*/

@import url("https://fonts.googleapis.com/css2?family=Puritan:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.form-check-input[type=checkbox] {
  border-radius: 0.25em;
  position: absolute;
}

#ffcenter {
  padding: 25px;
}

.paydiv {}

.login_bg {
  border: 1px solid green;
  display: flex;
  justify-content: center;
}

.email_email {
  width: 300px;
  height: 40px;
  border-radius: 4px;
  margin: 10px;
  border: 1px solid #ff00fe;
}

.passwardwer {
  width: 300px;
  height: 40px;
  border-radius: 4px;
  margin: 10px;
  border: 1px solid #ff00fe;
}

.login_button {
  width: 300px;
  height: 40px;
  border-radius: 4px;
  margin: 10px;
  background-color: #ff00fe;
}

.MuiDataGrid-row {
  background: #94c594;
  border: 1px solid black;

}

.kmsview {
  background-color: #04c22a;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.MuiButton-root.MuiButton-text.MuiButton-textPrimary.MuiButton-sizeMedium.MuiButton-textSizeMedium.kmsview.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
  background: blue;
  color: white;
}


.thank-you-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.etavisatype {
  text-align: center;
}

.thank-you-heading {
  font-size: 3rem;
  color: #ff5733;
  /* You can change colors here */
  /* Add more styles as needed */
}

#payhide {
  color: red;
  display: flex;
  align-items: flex-start;
}

.pagesa4kms {
  background-color: #fff;
  padding: 10px;
  text-align: start;
}

.red {
  color: red;
}


#country-list {
  color: rgb(11, 11, 167);
  text-align: center;
  font-weight: 600;
}

#lkj {
  text-align: center;
  font-weight: 600;
}

#as2df {
  color: rgb(11, 11, 167);
  text-align: center;
  font-weight: 600;
}

#bhytr5 {
  color: rgb(11, 11, 167);
  text-align: center;
  font-weight: 600;
}

.contact-design {
  color: rgb(11, 11, 167);
  text-align: center;
  font-weight: 600;
  font-size: 23px;
}

#bhytr5w {
  color: rgb(255, 121, 18);
  text-align: center;
  font-weight: 600;
}

#lko09 {
  border: 3px solid gray;
  padding: 15px;
  margin: 45px;
  text-align: justify;
}


.Applybutton {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  text-align: b;
}

.btn-apply {
  margin: 7px;
  background: linear-gradient(to right, #54d2e0, #0857b1);
  text-decoration: none;
  border-radius: 10px;
  text-align: center;
  width: auto;
}

.butn_appy123 {
  margin: 7px;
  background-color: #0956c7;
  text-decoration: none;
  height: 70px;
  border-radius: 4px;
  text-align: center;
  width: 340px;
}

.butn_appy123x {
  margin: 7px;
  background-color: #17ae68;
  height: 70px;
  border-radius: 4px;
  text-align: center;
  width: 330px;
}

.butn_appy123 h4 {
  font-weight: 600;
  margin-top: 12px;
}

.btn-apply h4 {
  font-weight: 600;
  margin-top: 11px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.butn_appy123x h4 {
  font-weight: 700;
  margin-top: 12px;
}

h4,
h3,
h2 {
  color: rgb(11, 11, 167);
}

h4.white {
  color: white;
}

.gsrilanka {
  margin-top: 30px;
}

.krish {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  color: white;
  background: #63A9EA;
  border-radius: 10px;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.ifrm {
  width: 1280px;
  height: 749px;
}

.adimgc {
  width: 80%;
  height: 4rem;
  object-fit: contain;
}

.cards-containerq {
  background-color: transparent;
}

.cardq {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin: 1rem 2rem;
  object-position: top;
  border: none;
  padding: 0rem 2rem;
  background-color: transparent;
}

#dropdown-basic {
  background: transparent;
  border: none;
}

.formvisa_apply {
  background-color: #2d90bd;
  text-align: center;
  font-weight: 700;
  font-size: 22px;
}

button#dropdown-item-button {
  width: 385px;
}

.dropdown-menu.show {
  background-color: #ffffff;
  margin-left: 42px;
}

.dropdown-menu.show {
  background-color: rgb(255, 255, 255) !important;
}


.mnop {
  height: 30px;
  margin-right: 1rem;
  font-size: 1.5rem;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  text-decoration: none;
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
}

.mno {
  /* margin: 10px; */
  height: 40px;
  margin-right: 1rem;
  font-size: 2.3rem;
  color: rgb(4, 34, 66);
  /* background-color: red; */
  text-decoration: none;
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
}

.mnopp {
  color: #000;
  text-decoration: none;
}

.mnop:hover {
  color: #9DBDDB;
  scale: 1.5;
}

.mno:hover {
  color: #0ea2ec;
  scale: 1.5;
}

.dropdown-menu.show {
  /* background: #000; */
  background-color: #082132;
  color: black;
}

.Dropdown.Item {
  color: black;
}

.ben-10 {
  color: black;
  background-color: transparent;
  font-size: 1.4rem;
  border-radius: 50px;
  padding: 0rem 2rem;
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
  border: 2px solid black;
}

.ben-9 {
  color: rgb(251, 251, 251);
  background-color: transparent;
  font-size: 1.4rem;
  border-radius: 50px;
  padding: 0rem 2rem;
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
  border: 2px solid rgb(7, 181, 249);
}

.ben-9:hover {
  background-color: rgb(7, 181, 249);
  color: #000;
  /* font-weight: 700; */
}


.ben-11 {
  color: black;
  background-color: transparent;
  font-size: 1.5rem;
  border-radius: 50px;
  padding: 0rem 2rem;
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
  border: 2px solid black;
}

.btns {
  color: black;
  background-color: transparent;
  font-size: 1rem;
  border-radius: 0px;
  padding: .7rem 1rem;
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
  border: 2px solid black;
}

.btns:hover {
  background-color: #082132;
  border: 2px solid white;
  -webkit-box-shadow: 10px 10px 10px#082132;
  box-shadow: 10px 10px 10px#082132;
  color: white;
}

.btnm {
  color: black;
  background-color: transparent;
  font-size: 1rem;
  border-radius: 20px;
  padding: .7rem 1rem;
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
  border: 2px solid black;
}

.btnm:hover {
  background-color: #082132;
  border: 2px solid white;
  -webkit-box-shadow: 10px 10px 10px#082132;
  box-shadow: 10px 10px 10px#082132;
  color: white;
}

.ben-12 {
  color: black;
  background-color: transparent;
  font-size: 1.4rem;
  border-radius: 0px;
  padding: 0rem 2rem;
  -webkit-transition: all ease .3s;
  -o-transition: all ease .3s;
  transition: all ease .3s;
  border: 2px solid black;
}

.ben-12:hover {
  background-color: #082132;
  border: 2px solid white;
  -webkit-box-shadow: 10px 10px 10px#082132;
  box-shadow: 10px 10px 10px#082132;
  color: white;
}

.ben-11:hover {
  background-color: #082132;
  border: 2px solid white;
  -webkit-box-shadow: 10px 10px 10px #082132;
  box-shadow: 10px 10px 10px #082132;
  color: white;
}

.ben-10:hover {
  background-color: #082132;
  border: 2px solid white;
  -webkit-box-shadow: 10px 10px 10px #082132;
  box-shadow: 10px 10px 10px #082132;
  color: white;
}

.advs {
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  font-size: 3rem;
  padding: 1rem;
  margin-top: -1rem;

}

.advs>p {
  font-size: 1.5rem;
}

center h1 {
  font-size: 2rem;
  margin: 2rem 0rem;
}

.il {
  font-size: 1.2rem;
}

center p {
  font-size: 2rem;
}

.p {
  background-color: #000;
}

h1>span {
  font-size: 2rem;
  color: #2c84c2;
  -webkit-filter: drop-shadow(0 0 1rem rgb(4, 34, 66));
  filter: drop-shadow(0 0 1rem rgb(4, 34, 66));
}

.j {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.j h3 {
  font-weight: bold;
}

@media only screen and (min-width : 320px) {
  .welcomeText {
    margin: 10px;
  }
}

.card-contentq h2 {
  font-size: .8rem;
  font-weight: bolder;
}

.card-contentq p {
  font-size: 1.2rem;

}

.cv {
  background-color: #b7d0ee;
}

.l {
  margin-bottom: 5rem;
}

.mb {
  margin-top: 7rem;
}

.ll {
  font-size: 3.5rem;
}

.s {
  margin-left: 4rem;
}

.p {
  margin-top: 7rem;
}

.d {
  margin-right: 10rem;
}

.v {
  /* margin-top: -3rem; */
  /* margin-right: 8rem; */
  margin-top: 3rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: -3rem;

}

/* .text_item .x{
  margin-top: 3rem;
} */
.nnn {
  /* margin-left: 0rem;   */
  width: 130px;
  margin-top: 2rem;
}

.dv {
  margin-right: 13rem;
}

h1>span {
  color: #012641;
  font-size: 1rem;
  /* margin-top: 1rem;
   */
  /* padding-top: 3rem; */
  -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
  filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
}

h3>span {
  color: #012641;
  font-size: 1.7rem;
  /* margin-top: 1rem;
   */
  /* padding-top: 3rem; */
  -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
  filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
}

/* 
.card-containerq h2{
  font-size: .5rem;

} */

.about_c_ssxx h4 {
  font-size: 2rem;

}

.ds {
  margin-top: 2rem;
  font-size: 1.5rem;

}



/* end of changes by me  */
* {
  margin: 0px;
  padding: 0px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.imgbuto {
  width: 400px;
}

.higw1 {
  margin-top: 1rem;
  border-radius: 2px solid grey;
  padding: 0rem 1.5rem;
}

.higw1>img {
  border-radius: 20px solid grey;
  border: 10px;
  -o-object-position: top;
  object-position: top;
  -webkit-box-shadow: 0px 10px 10px grey;
  box-shadow: 0px 10px 10px grey;
}

.border1 {
  margin: 0rem 2rem;
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-style: none !important;

}

.border1>.text-item {

  margin-top: -3rem;
}

.slider {
  width: 200px;
  margin: auto;
  text-align: center;
}

.slide-container {
  overflow: hidden;
  width: 200px;
  height: 200px;
}

.slide {
  display: none;
}

.active {
  display: block;
}

img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.sm {
  margin-left: 4rem;
}

.footer_list li a {
  text-decoration: none;
  color: white;
  line-height: 0%;
}

.footer_list li a:hover {

  color: #1A509C;
}

.footer_list {
  line-height: 90%;

}

html,
body {
  font-family: Verdana, Geneva, sans-serif 'Inter';
  font-size: 18px;
  color: #000;
  overflow-x: hidden;
  font-weight: 500;
}


.card_blog {
  width: 40vw;
}

.centerblog {

  margin: 0 auto;
  padding: .5rem .5rem;

  border-radius: 1px;
  background-color: #efefeff1;

  /* margin-bottom: 2rem; */
}

.img_card {
  width: 390px;
  height: 270px;
  /* padding: 10px; */
  margin-left: -40px;
  border-radius: 5px;

}

.blog_img {
  width: 200px;
  height: 200px;
  /* barder-radius: 5px 5px 0 0; */
  /* border: 1px solid ; */
}

.sbtn1 {
  background: none;
  /* border-radius: 8px; */
  margin-left: .3rem;
  font-size: 1.5rem;
  background-color: white;
  border: none;

  color: black;
  border-radius: 5px;
  width: 40px;
}

.sbtn1:hover {
  background-color: #0B162A;
  color: white;
}

.centerCol {
  width: 100%;
  max-width: 1500px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: sticky !important;
  padding: 10px;
}

.centerCol h2 {
  text-align: center;
  font-weight: bold;
  margin: 10px;
}

.centerCol p {
  text-align: center;
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  .centerCol {}
}

ul {
  margin-left: 20px;
}

.footer_list li:hover {
  color: grey;

}

.cibox {
  width: 400px;
  height: 400px;
}

.footer_list li {
  margin: 10px;
}

.bgcontac {
  background-color: #D2ECF8;
  -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
  filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
  margin: 3rem 0rem;

}

.contact-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#question-txt {
  font-size: 1.5rem;
}

.ctexta {
  color: #000000;
  font-size: 12px;
  font-weight: 600;
  margin-left: 30px;
}

.border_colo {
  /* background: linear-gradient(white, white) padding-box,
              linear-gradient(to right, darkblue, darkorchid) border-box;
  border-radius: 4.5em;
  border: 5px solid transparent;   */
  /* border: 1px solid #0c0b3b; */
  border-radius: 5px;
  -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
  filter: drop-shadow(0 0 1rem rgb(4, 42, 83));

  /* box-shadow: 10px 10px rgb(211, 210, 210); */
  margin: 20px;

}

.marginpx {
  margin: 5px;
}

.about_c_ss {
  width: 500px;
  height: 400px;
}

.about_c_ssxx {
  width: 450px;
  height: 400px;

}

.displayflex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.displayflex1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.imgicons {
  width: 30px;
  height: 30px;
  margin: 5px;
}

.map {
  width: 400px;
  height: 400px;
}

.map1 {
  width: 400px;
  height: 400px;
}

.card_flex1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.row_flex_card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

}

.red1 {
  position: relative;
}

.more_btn {
  width: 295px;
  height: 47px;
  font-family: 'Source Code Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 50px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background: #0b1230;
  border-radius: 25px;
  color: #FFFFFF;

}

.ttrr12 {
  /* font-size: smaller; */
  font-weight: 600;
  font-size: .9rem;
  color: rgb(56, 54, 54);
  /* text-align: center; */
}

.kjhg {
  background: #dedddb;
  font-size: x-large;
  font-weight: bolder;
  margin-bottom: 19px;
  padding: 10px;
  border-radius: 10px;
}

.center {
  text-align: center;

}

.rowflex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin: 20px;
}

.icons li {
  font-size: 40px;
  margin: 10px;
}

.icons li:hover {
  color: #1A509C;
  cursor: pointer;
  /* color: red; */
  /* padding: 5px; */


}

.u_head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.email1 {
  margin-top: 25px;
}

.email1 p a {
  text-decoration: none;
  color: black;
  font-weight: bold;
}

.carouselbox {
  max-width: 1500px;
  height: 500px;
}

.page-header {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: linear-gradient(to right, #54d2e0, #0857b1);
}

.moving_tag {
  color: #01051c;
  font-weight: bold;
}

.cardbody123 {
  margin-top: 30px;
}

.navigationBox {
  width: 100%;
  height: 50px;
  padding: 11px;
  background-color: #082132;
  color: white;
  display: flex;
  justify-items: center;
  align-items: center;
}

.naviBox.with-margin {
  top: 0;
}

.navbar_h {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  list-style: none !important;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 97%;
  position: sticky !important;
}

.navbar_h li a {
  text-decoration: none;
  color: #fff;
}

.navbar_h li a:hover {
  color: #9dbddb;
  padding: 5px;

}

.menu_btn {
  display: none;
}

.nomore {
  margin-top: -8rem;
  color: rgb(35, 33, 33);
}

.border_dot {
  border: 2px dotted white;
  margin: 2px;
}

.circle {
  width: 200px;
  height: 120px;
  border: 1px;
  border-radius: 10%;
  margin: 10px auto;

  background-repeat: no-repeat;
  background-size: contain;

}


.media_card:hover {
  cursor: pointer;
  scale: 1.5;
  z-index: 5;
  transition: all .3s ease-in-out;
}

.super-flex {
  margin-top: 1rem;
  margin-bottom: 2rem;

}

.bbb {
  text-align: center;
  color: white;
}

.black {
  color: black;
}

.c {
  margin-top: -1rem;
}

.bbb1 {
  text-align: center;
  color: black;
  font-weight: 500;
  margin-top: 2rem;
  font-size: 2.3rem;
  padding: 20px;
}

.b1 {
  text-align: center;
  font-size: 3rem;
}

.b1>span {
  color: #1A509C;
  -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
  filter: drop-shadow(0 0 1rem rgb(4, 42, 83));


}


.bbb1>span {
  color: #1A509C;
  -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
  filter: drop-shadow(0 0 1rem rgb(4, 42, 83));

  font-size: 3rem;
}

.row_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -webkit-box-pack: justify;

  -ms-flex-pack: justify;

  justify-content: space-;
}

.row_flex li {
  margin: 10px;
  height: 20px;
  font-size: 1.5rem;

}

.row_flex li:hover {
  color: #1A509C;
  cursor: pointer;
  /* color: red; */
  /* padding: 5px; */
}


.card_head {
  /* text-align: center; */
  font-weight: 500;
  font-size: 1.2rem;
  color: #0a0a0a;
}

.card_head a {
  color: red;
}

.kmsxyz {
  margin-bottom: 3rem;
}

.sub_t {
  text-align: center;
  color: #f01cca;
}

.hov:hover {
  background-color: #1A509C;
  color: white;
  -webkit-box-shadow: 20px 20px 30px 25px rgba(2, 2, 2, 0.25);
  box-shadow: 20px 20px 30px 25px rgba(2, 2, 2, 0.25);
}

.hov {
  font-size: .8rem;
  border-radius: 10px;
  background: rgba(210, 236, 248, 0.58);
  -webkit-box-shadow: 10px 10px 30px 10px rgba(79, 77, 77, 0.25);
  box-shadow: 10px 10px 30px 10px rgba(79, 77, 77, 0.25);
}

.rectangle {
  width: 300px;
  height: 200px;
  background-color: #01051c;
}

.ttray {
  border-radius: 10px;
  /* background-color: #63A9EA; */
  /* margin-bottom: 6.5rem; */

}

center>p {
  font-size: 1.2rem;
  font-weight: 100;
  color: #000000b4;
}

center>h3 {
  font-size: 1.8rem;
  font-weight: 530;
}

.about_cont {

  color: white;
  background: #63A9EA;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 10px;
}



.bbb {
  border: 1px solid red;
  color: red;
}

.upper_header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: white;
  background-color: #082132;
}







.f_boxo {
  margin-top: 6rem;
  max-width: 400px;


}

.icon_d_size {

  width: 40px;
  height: 40px;
}

.imgcus1 {
  width: 300px;
  height: 300px;
}

.rtyrev {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.pd {
  padding: 2rem 2rem;
}

.row_dds1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

}

.mar {
  margin-bottom: -5rem;
}

.l {
  margin-top: 6rem;

}

.row_dds {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}


.para_cent {

  /* width: 10vw; */
  width: 40vw;

}

.rflex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border: 1px solid red;

}

.Text_box>p {
  font-size: 1.2rem;
  color: rgb(42, 41, 41);
}

.Text_box>center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 30px;


}

.Text_box h5 {
  font-size: 2rem;
  font-weight: 300;
}

/* .Text_box p{
  font-size: 1rem;
  font-weight: 100;
} */

.xxxx {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

}

.krowe {

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.boxx {
  width: 400px;
  width: 400px;
  border: 2px solid green;
}

/* .row_dds1{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;

} */
.new {
  /* display: flex; */
  /* margin-right: 3rem; */
  margin-left: -20rem;

}



.row_dds {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: -4rem;
}

.row_dds button {
  margin-top: 2rem;
}

.card_body_tyu {
  /* height: 150px; */
  /* margin: 10px; */
  border-radius: 2px;
  margin-left: 3rem;
  margin-top: 2rem;

  /* box-shadow: 2px 2px 2px grey; */

}

.search_d {
  display: none;
}

.div_box {
  margin-top: 2rem;
  /* width: 500px;
  height: 400px; */
  /* margin-top: 3rem; */
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.flexr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}


.btn_blog {
  width: 150px;
}

.boxfit {
  width: 500px;
  height: 400px;
}



.myyl {
  margin-top: 3rem;
}

.textxx>p {
  color: black;
  font-size: 1.6rem;
}

.textxx>h4 {
  font-size: 3rem;
}

.ftflex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.box_homxx {
  width: 500px;
  height: 300px;
  margin-top: 3rem;
  -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
  filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 10px;
}

.textxx {

  margin-top: 20px;
}

.rupaliii {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.rupaliii1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.rupaliii1>.bgvf {
  margin-left: 10rem;
}

.box_home1 {

  width: 500px;
  height: 500px;
  position: relative;

}

.bgc_gtr {
  border-radius: 10px;
  background: rgba(210, 236, 248, 0.58);
  margin: auto;
}

.m_auto {
  margin: auto;
}

.boxdf {
  height: 100px;
  border: 2px solid rgb(255, 255, 255);
  background: #012641;
  color: white;
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
}

.text_item {
  /* padding: 50px; */
  margin-top: 6rem;
  width: 40vw;

  /* padding: 50px; */
}

.text_ite {
  width: 45vw;
  margin-top: 2rem;
  /* margin-bottom: 2rem; */
}

.text_ite>center {
  margin-top: 2rem;
}

.text_ite h4 {
  font-size: 2.5rem;
  font-weight: 400;
  color: rgb(7, 36, 66);
  -webkit-filter: drop-shadow(0 0 1rem rgb(4, 42, 83));
  filter: drop-shadow(0 0 1rem rgb(4, 42, 83));



}

.text_item h1 {
  font-size: 1.9rem;
  font-weight: 400;
  color: rgb(54, 54, 54);

}

span {
  font-size: 2.2rem;
  color: #0e90bf;
}

.text_item p {
  font-size: 1.2rem;
  font-weight: 400;
  color: rgb(63, 61, 61);
  /* width: 40vw; */

}

.im2 {
  width: 100px;
}

.kms {
  width: 200px;
  height: 200px;
  background-color: #f01cca;
  position: relative;
}

.kms1 {
  width: 200px;
  height: 200px;
  background-color: #1cf02e;
  position: absolute;
  left: 75px;
  top: 100px;
}

.wid400px {
  height: 300px;
}

.iconsrr {
  width: 45px;
  height: 45px;
}

.higw1 {
  width: 300px;
  height: 250px;
  -o-object-fit: cover;
  object-fit: cover;
  /* display: flex; */
}

.higw2 {
  width: 600px;
  height: 250px;
}

.higw3 {
  width: 200px;
  height: 180px;

}

.row1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 3rem;
  /* padding: 1rem 2rem; */
  /* background-color: #2887ed4e; */
}

/* .center{ 
  align-items: center;
} */
/* footer */


.footer_body.border {
  margin: 10px;
}

.side_bar {
  list-style: none;
  /* background-color: #1A509C; */
}

.side_bar li a {
  text-decoration: none;
  color: #000;
}

.btn_search {
  color: white;
}

.none {
  display: none;
}

.rupl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

}




.containerxx {
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  background: #012641;
  padding: 15px;
}

.containerxx img {
  width: 100%;
  display: block;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  -webkit-transition: all 100ms ease-out;
  -o-transition: all 100ms ease-out;
  transition: all 100ms ease-out;
}

.containerxx img:hover {
  -webkit-transform: scale(1.04);
  -ms-transform: scale(1.04);
  transform: scale(1.04);
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.mmru {
  display: none;
}

.inpsearch {
  width: 260px;
  height: 30px;
  font-size: .8rem;
  border: none;
  border-radius: 5px;
  padding: 15px;
  /* margin: 5px; */

}

.d-flex .form-control {
  font-size: .8rem;
  width: 20vw;

}

.home_btn_menu {
  width: 25px;
  height: 30px;
}

.rrrrrr {
  float: right;
}

.rewad:hover {
  scale: 6;
  z-index: 10;
}


.fffht {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.respad {
  margin: 3rem 2.5rem;
  padding: auto;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.bimg>img {
  width: 100%;
}

.input_width {
  width: 100%;
  /* margin: 0rem -3rem; */
}


/* #Media Queries
================================================== */
@media only screen and (max-width: 575px) {

  .navbar_h {
    display: none;
  }

  .small-screens .smscreens {
    color: red;

  }


  .menu_btn {
    display: block;
  }

}

.cards-containerq {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 6px;
  color: #333;
  background: #ffffffc6;
  padding: 1rem;
  -webkit-box-shadow: 0 0 1rem #404b4f inset;
  box-shadow: 0 0 1rem #404b4f inset;
  overflow-x: auto;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
}

.testcardimg {
  height: 50px;
  width: 50px;
  margin-left: 100px;
  margin-top: 10px;
  border-radius: 50%;
}

.cards-containerqt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 6px;
  color: #333;
  background: #151616c6;
  background-color: transparent;
  /* padding: 1rem 1rem; */
  -webkit-box-shadow: 0 0 1rem #cabbbb inset;
  box-shadow: 0 0 1rem #cabbbb inset;
  overflow-x: auto;
  position: relative;
  /* justify-content: center; */

  &::-webkit-scrollbar {
    display: none;
  }
}


.cardq {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 250px;
  flex: 1 0 250px;
  -webkit-box-shadow: 0 1px 1rem -4px #000;
  box-shadow: 0 1px 1rem -4px #000;
  background: #fff;
  /* margin: 1rem; */
  overflow: hidden;
  /* border-radius: 6px; */
  cursor: pointer;
  -webkit-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;

  img {
    width: 300px;
    height: 250px;
    -o-object-fit: center;
    object-fit: center;
    /* margin: 10px; */
    margin-left: -3.7rem;
    padding: 10px;
  }

  &:hover {
    -webkit-box-shadow: 0 4px 1rem -30x #000;
    box-shadow: 0 4px 1rem -30x #000;
    -webkit-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}

.cardqtt {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-box-shadow: 0 1px 1rem -4px #0f96eb;
  box-shadow: 0 1px 1rem -4px #0f96eb;
  background: #f7f7f7;
  margin: 1.5rem;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 6px;
  padding: 0rem 1rem;
  width: 30vw;
  /* width: 60vw; */
  cursor: pointer;
  -webkit-transition: all 250ms ease-in-out;
  -o-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;

  img {

    display: none;
  }

  p {
    font-size: 1rem;
    /* width: 30rem; */
  }

  h2 {
    font-size: 1rem;
    font-weight: bolder;
    align-items: center;
    justify-content: center;
    margin-top: .8rem;
  }

  &:hover {
    -webkit-box-shadow: 0 4px 1rem -4px #000;
    box-shadow: 0 4px 1rem -4px #000;
    -webkit-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    transform: translateY(-3px);
  }
}

/* .Accordion-Body{
  display: flex;
  justify-content: start;
} */
.card-content {
  padding: 1rem;
}

@media screen and (max-width: 625px) {
  .containerq {
    width: 100%
  }

  .cards-containerq {
    padding: .5rem;
  }

  .cardq {
    margin: .5rem;
  }
}

@media only screen and (max-width: 795px) {
  /* .Applybutton{ 
  flex-wrap: wrap;
  position: absolute;
  transform: translate(-55%, -74%);
  top: 399px;
  left: 428px;
} */

}


@media only screen and (max-width: 455px) {
  .logo11 {
    display: none;
  }


  .cardqtt {
    flex: 1 0 auto;
    width: 21rem;
  }

  .ifrm {
    width: 390px;
    height: 2rem;
  }

  .super-flex {
    display: flex;
    /* padding:0rem 3rem ; */
    flex-direction: row;
    row-gap: -5rem;
    margin-left: -2rem;
  }

  .center {
    font-size: .8rem;
  }

  .icons {
    margin-left: -4rem;

  }

  .f_col {
    margin-bottom: -2rem;
  }

  .footer_body {
    margin-bottom: -2rem;
  }

  .media_card {
    /* display: none;
     */
    margin: 0rem -4rem;
    /* margin-bottom: -2rem; */

  }

  .mode-card {
    margin-bottom: -2rem;
  }

  .circle {
    margin-bottom: -3.4rem;

  }

  .ben-11 {
    font-size: 1.2rem;
    padding: 1rem 1rem;
  }

  .rewad:hover {
    scale: 3;
    z-index: 10;
  }

  .ben-12 {
    font-size: 1.2rem;
    padding: 0.3rem 1rem;
  }

  .ben-10 {
    padding: 0.2rem 1rem;
    font-size: 1.6rem;
  }

  .btns {
    font-size: 1rem;
    padding: .3rem 1rem;
  }

  .textxx {
    margin-top: -1rem;
  }

  .bimg>img {
    min-width: 100%;
  }

  .higw2 {
    margin-top: 5rem;
    margin-bottom: -2rem;
  }

  .bx_home12 {
    margin-bottom: -18rem;
  }

  .mera {
    margin-top: -2rem;
  }

  .ds {
    padding: 0rem 2rem;
    margin-left: -1rem;
    margin-top: 0rem;
    ;
  }

  .textxx>p {
    color: black;
    font-size: 1.3rem;

  }

  .displayflex1 {
    margin-bottom: 2.5rem;

  }

  .box_hom2 {
    width: 400px;
    height: 300px;
  }

  .btnm {
    font-size: 1.2rem;
    padding: .4rem 1rem;
  }

  .nnn {
    /* margin-left: 1rem; */
    /* gap: 1px; */
    margin-top: -1rem;
  }

  .new {

    margin-left: 0rem;

    width: 100%;
    padding: 0rem 2rem;
  }

  .box_home12 {
    width: 400px;
    height: 300px;
    margin-top: 3rem;
  }

  .fffht {
    margin-top: 3rem;

  }

  .flexxx {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 1rem;
    /* margin-top: 3rem; */

  }

  .flex {
    margin-bottom: 5rem;
  }

  .mar {
    margin-top: -6rem;
  }

  .bg_hyt {
    width: 400px;
    height: 300px;
    margin-left: 3rem;
  }

  .ma {
    margin-bottom: -2rem;
  }

  .para_cent {
    width: 100%;
    margin-bottom: 1rem;
  }

  .bg_im7 {
    width: 500px;
    height: 400px;
    /* margin-top: -5rem; */
    margin-bottom: -10rem;

  }

  .n {
    margin-bottom: 3rem;


  }

  span {
    font-size: 2rem;
    color: #0e90bf;
  }

  .bbb1>span {
    font-size: 1.5rem;
  }


  .fffht>.box_hom12 {
    /* margin-right:5rem ; */
    margin-top: 8rem;
    width: 500px;
    height: 400px;
    margin-bottom: 1rem;
  }

  .text_item {
    margin-top: -.5rem;
  }

  .navbar_h {
    display: none;
  }

  .Text_box>center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .menu_btn {
    display: block;
  }

  .kmssa {
    display: none;
  }

  .btn_search {
    display: none;
  }

  .text_item {
    width: 100%;

  }

  .text_item h1 {
    font-size: 1.7em;

  }

  .div_box {
    width: 100%;
    margin: 0;
  }

  #logoimg {
    border: 1px solid red;
    width: 200px;

  }

  .white {
    color: white;
  }

  .icon_h {
    display: none;
  }

  .media_card {
    margin-top: 10px;
    padding-bottom: 3rem;
    margin-left: 70px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /* flex-direction: column; */
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .icons_flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 100px;
    margin-left: -20px;

  }

  .none {
    display: block;
  }

  .fffht {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: -4rem 2rem;
    margin-top: -9rem;
  }

  .search_b {
    width: 200px;
  }

  .paraexp {
    width: 310px;
  }

  .mmru {
    display: block;
  }

  .rcarouselbox {
    max-width: 1500px;
    height: 200px;
  }

  .bg_img7 {

    height: 350px;
  }

  .box_home {
    height: 350px;
  }

  .box_hom {
    height: 350px;
  }

  .bg_img6 {
    height: 315px;
  }

}




.containerqq {
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 400px;
  width: 400px;
  background-color: white;
  border-radius: 15px;
  /* box-shadow: 2px 2px 10px 1px rgb(102, 3, 102); */
  box-shadow: 2px 2px 10px 1px #333;
  overflow: hidden;
  padding: 10px;
  position: relative;
}

.heading-txt {
  color: white;
  /* color:#276272; */
  font-size: 30px;
  font-weight: bold;
  margin: 10px;
  text-align: center;
  text-transform: uppercase;
}

.question {
  margin: 8px;
  box-shadow: 0px 0px 10px 1px #a2a3a3;
  border-radius: 5px;
  padding: 5px;
  font-size: large;
  font-weight: 600;
  font-family: 'Times New Roman', Times, serif;
  min-width: 100%;
  min-height: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#question-number {
  margin-right: 5px;
}

.option-container {
  display: flex;
  flex-direction: column;
  margin: 6px 4px;
  width: 100%;
  padding: 10px;

  height: 400px;
}

/* .option-container input{
  visibility: hidden;    
} */
.option-btn {
  box-shadow: 0px 0px 4px 1px rgb(128, 123, 123);
  padding: 5px;
  border-radius: 3px;
  border: none;
  outline: none;
  transition: 0.3s;
  margin: 5px;
  min-height: 30px;
  background-color: white;
}

.checked {
  background-color: rgb(179, 83, 179);
  color: white;
  box-shadow: 3px 3px 4px 1px rgb(78, 69, 78);
}

.option-btn:hover {
  background-color: rgb(212, 118, 212);
  color: white;
}

#next-button {
  padding: 10px;
  border: none;
  background-color: purple;
  background-color: #276272;
  color: white;
  border-radius: 5px;
  box-shadow: 3px 3px 2px 1px rgb(127, 109, 109);
  font-size: larger;
  font-weight: bold;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  transition: 0.2s;
  width: 100px;
  position: absolute;
  bottom: 15px;
}

#next1-button {
  padding: 10px;
  border: none;
  background-color: purple;
  background-color: #276272;
  color: white;
  border-radius: 5px;
  box-shadow: 3px 3px 2px 1px rgb(127, 109, 109);
  font-size: larger;
  font-weight: bold;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  transition: 0.2s;
  width: 100px;
  position: absolute;
  bottom: 15px;
}

#next-button:active {
  box-shadow: none;
}

.show-score {
  position: absolute;
  top: 30%;
  font-size: 40px;
  text-align: center;
}

.payment_declair {
  border: 1px solid red;
}

.kmspaymentlast {
  margin: 0;
  padding: 30px;

}

.partialpay {
  padding: 10px;
}

.preview_img img {
  width: 200px;
  margin: 10px;
  padding: 10px;
}

#captchacolor {

  background-color: #92dba0;
  padding: 5px;
}

.errorcode {
  color: red;
  font-size: 20px;
}

.parent-component {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.parent-component p {
  margin: 0;
  padding: 0;
  font-size: 25px;
  font-family: Inter-UI-400;
  color: #0956c7;
}

.logo-and-name {
  display: flex;
  text-align: left;
  justify-content: left;
  align-items: center;
  gap: 15px;
}

.logosrilanka {
  width: 100px;
  height: 100px;
  margin: 2px;
}

.star-image {
  height: 100px;
  width: 90px;
}

.flex-container {
  display: flex;
  text-align: left;
  justify-content: left;
  margin-left: 15%;
  align-items: center;
  /* todo Need to add media quert */
}

.flex-container .no-gap {
  margin: 0;
  padding: 0;
  font-size: 25px;
  font-family: Inter-UI-400;
  color: #0847a5;
  font-weight: bold;
}

.flex-container .p-group {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.carousal {
  position: relative;
 
  /* Make the container a positioning context */
}



.cont_btn {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap !important;
  gap: 20px;
}

.btn-apply {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto !important;
}

.white {
  color: white;
}

ol {
  list-style-type: decimal;
}

.background-image {
  width: 100%;
  height: 400px;
  object-fit: fill
}

@media (min-width: 770px) and (max-width: 933px) {

  .background-image {
    object-fit: fill;
    width: 100%;
    height: 250px;
  }

  .btn-apply {
    height: auto;
    width: auto;
  }

  .btn-apply h4 {
    font-size: 18px;
  }

  .cont_btn {
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
    gap: 5px;
  }

  .tbale {
    width: auto;
  }
}

@media (min-width: 666px) and (max-width: 769px) {


  .background-image {
    object-fit: fill;
    width: 100%;
    height: 250px;
  }

  .btn-apply {
    height: 32px;
    width: auto;
  }

  .btn-apply h4 {
    font-size: 15px;
  }

  .cont_btn {
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
    gap: 5px;
  }
}

@media only screen and (max-width: 540px) {

  .background-image {
    object-fit: fill;
    width: 100%;
    height: 250px;
  }

  .btn-apply {
    height: 32px;
    width: 130px;
  }

  .btn-apply h4 {
    font-size: 10px;

    /* flex-direction: row-reverse; */
  }

  .cont_btn {
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    /* text-align: center; */
    display: flex;
    /* justify-content: center; */
    flex-wrap: nowrap;
    flex-direction: row-reverse;
  }
}

@media (min-width: 934px) and (max-width: 1025px) {
  .btn-apply h4 {
    font-size: 28px;
  }

  .btn-apply {
    height: auto;
    width: auto;
  }
}





.canvas-design {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  font-size: 20px;
  font-weight: bold;
}


.offcanvas-header-custom {
  display: flex;
  justify-content: flex-end;
  /* Pushes the close button to the end */
  align-items: center;
  /* Centers items vertically within the header */
  padding: 1rem;
  /* Adjust padding as needed */
}

.offcanvas-title-custom {
  flex: 1;
  /* Allows the title to take available space */
  text-align: center;
  /* Center-aligns the title text */
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 80%;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.typing-animation {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  animation: typing 3s steps(40, end), blink 0.75s step-end infinite;
  margin: 0;
  padding: 0;
  font-size: 25px;
  color: #044ebc;
  font-weight: bold;
}

.custom-checkbox-label {
  font-size: 18px;
  color: black;
}

/* Default table styles */
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

/* Responsive table */
@media (max-width: 768px) {
  table {
    display: block;
  }
}

@media (max-width: 576px) {

  th,
  td {
    font-size: 17px;
    padding: 4px;
  }
}

.center-button {
  display: flex;
  justify-content: center;
}

.page-title {
  border-bottom: 2px solid black;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.page-title h2 {
  font-weight: 700;
}

.page-sub-title {
  color: #0956c7;
  font-size: 24px;
  text-align: center;
}

.page-main-container {
  background-color: #fff;
  padding: 20px;
}

.page-main-container p {
  font-weight: 500;
}

.page-main-container li {
  font-weight: 500;
}

.order-list {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.order-list-sub-content {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.order-list-sub-content li {
  font-weight: 600;
}

.sub-order-list {
  display: flex;
  gap: 5px;
  flex-direction: column;
}